// Triple hyphens on lines 1 and 2 are required by Jekyll in order to
// process SCSS into CSS.
// 
// Note that your code editor may syntax highlight the first import statement
// below as being in an error state due to the hyphens above which are part of
// Jekyll syntax and not SCSS syntax. An invalid syntax error, especially on
// the first meaningful line of code, might be a false positive.

// dependencies imports
@import '_modules/core/_utils';
@import '_modules/core/_media';
@import '_modules/theme-seychelles/_config';
@import '_modules/theme-seychelles/modal.scss';
@import '_modules/theme-seychelles/video-player.scss';
@import '_modules/theme-seychelles/_magnificPopup';

.home-banner {
  max-height: 950*$pxToRem;

  .banner__content {
    h1 {
      @include config-type-subheading;
      order: 0;
      margin: 0;
    }

    h2 {
      @include config-type-heading;
      order: 1;
      margin: 34*$pxToRem 0 4*$pxToRem 0;
    }

    h3 {
      @include config-type-body-lg;
      font-weight: 400;
      text-transform: none;
      font-size: 22*$pxToRem;
    }
  }
  @include media-mobile-only {
    min-height: 40vh;
  }
}

.news {
  margin-top: 135*$pxToVw;

  @include media-lg-monitor-only {
    margin-top: 135*$pxToRem;
  }

  @include media-mobile-only {
    margin-top: 0;
  }
}

// .message {
//   background-color: #000;
//   padding: 1rem;
//   text-align: center;
//   text-transform: uppercase;
//   animation: .5s ease 0s 1 slideInFromTop;

//   @media screen and (max-width: 768px) {
//     position: absolute;
//     top: 60px;
//   }

//   a {
//     font-size: 1rem;
//     letter-spacing: 5px;
//     color: #fff;

//     &:hover {
//       color: #2bcbdd;
//       cursor: pointer;

//     }

//     @media screen and (max-width: 768px) {
//       font-size: 1rem;
//       letter-spacing: 5px;
//     }

//   }

//   @keyframes slideInFromTop {
//     0% {
//       transform: translateY(-100%);
//     }

//     100% {
//       transform: translateY(0);
//     }
//   }

// }
