#modal,
.modal__overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

#modal {
  z-index: 10;
  display: none;
  align-items: center;
  justify-content: center;

  @include media-above-mobile {
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
  }
}

.modal__overlay {
  background: rgba($color-black, 0.8);
}

button.modal__close {
  position: fixed;
  top: 4*$pxToRem;
  right: 4*$pxToRem;
  padding: 30*$pxToRem;
  z-index: 11;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;

  svg {
    display: block;
    width: 30*$pxToRem;
    height: 30*$pxToRem;
    fill: $color-white;
    opacity: 0.66;
    transition: opacity $transition-default;
  }

  &:hover,
  &:focus-visible {
    svg {
      opacity: 1;
    }
  }
}

#modal__content {
  position: relative;
  z-index: 11;
  background: $color-black;
  width: 100%;
  aspect-ratio: 16 / 9;

  @include media-above-mobile {
    width: calc(100% - #{80*$pxToRem});
    max-width: 1920px;
    box-shadow: 0 10*$pxToRem 50*$pxToRem rgba($color-black, 0.5);
  }

  iframe {
    display: block;
    border: none;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    animation: fadeIn 1s forwards;
  }
}

.js-modal-is-active {
  #modal {
    display: flex;
    animation: fadeIn 0.15s forwards;
  }
}
