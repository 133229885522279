.video-player {
  overflow: hidden;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  & > *:not(.bg-cover-photo) {
    position: relative;
    z-index: 1;
    max-width: 500*$pxToRem;
  }

  h3 {
    @include config-type-body;
    text-transform: uppercase;
    font-weight: 700;
    color: $color-text-inverted;
    margin-bottom: 0;

    & + p {
      margin-top: 10*$pxToRem;
    }
  }

  .bg-cover-photo {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: transform $transition-default,
                filter $transition-default;
    transform: scale(1);

    &:after {
      transition: background-color $transition-default;
    }
  }

  .button-play-video {
    width: 60*$pxToRem;
    height: 60*$pxToRem;
    min-height: 60*$pxToRem;
    border: 1*$pxToRem solid $color-accent;
    margin-bottom: 26*$pxToRem;
    padding: 0;
    background: rgba($color-black, 0.1);
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);

    &:before {
      display: none;
    }
  
    @include media-above-mobile {
      margin-top: 66*$pxToVw;
    }
  
    @include media-lg-monitor-only {
      margin-top: 66*$pxToRem;
    }
  
    svg {
      transform: translateX(2*$pxToRem);
      position: relative;
    }
  }

  @include media-above-mobile {
    padding: 60*$pxToRem;
  }

  @include media-mobile-only {
    padding: $padding-mobile;
    padding-top: 50px;
  }
}

@include media-above-mobile {
  a.video-player {
    &:hover {
      &.inverted {
        color: $color-text-inverted !important;
      }
    
      .bg-cover-photo {
        transform: scale(1.025);
        filter: blur(7px) grayscale(0.67);
    
        &:after {
          background-color: rgba($color-gray-1, 0.8);
        }
      }
    }
  }
}
